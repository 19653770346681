import '@Components/Pages/Details/styles.scss'

import { Col, Row } from 'react-bootstrap'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { formatWeeksTimeString, formatAvailabilityNote, numberWithCommas } from '@Helpers'
import { handleUserDeauth, logError } from '@Services/'
import { httpGet, queryImageCache } from '@Services/API'

import { AuthContext } from '@Contexts/Auth'
import BarHeader from '@Components/shared/Headers/BarHeader'
import BasicCarInfo from '@Components/Pages/Details/BasicCarInfo'
import DamagePhoto from '@Components/Pages/Details/DamagePhoto'
import ImageGallery from 'react-image-gallery'
import { Link } from 'gatsby'
import VinCarInfo from '@Components/Pages/Details/VinCarInfo'
import noPhoto from '@Assets/noPhoto.png'
import { updateTimestamp } from '@Services/Credentials'

const buildPhotoGallery = ({ glamourphotos, manufacturerphotos, description }) =>
  glamourphotos?.map(photo => ({
    original: photo.image_url,
    thumbnail: photo.image_url
  }))
  || manufacturerphotos?.map(photo => ({
    original: photo.image_url,
    thumbnail: photo.image_url,
    originalClass: 'stock-photo-banner'
  }))
  || buildStockPhotoGallery(description)
  || []

const buildStockPhotoGallery = ({ year, model, make }) => {
  const searchTerm = `${year} ${make} ${model.includes(',') ? model.substring(0, model.indexOf(',')) : model}`
  const stockImageUrl = queryImageCache(searchTerm)

  return stockImageUrl ? [{
    original: stockImageUrl,
    thumbnail: stockImageUrl,
    originalClass: 'stock-photo-banner'
  }] : null
}

const matchStockId = /\/Details\/([0-9]+)/

const Details = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [activeCar, setActiveCar] = useState({ glamourphotos: [] })
  const [carPhotos, setCarPhotos] = useState([])
  const [infoModal, setInfoModal] = useState(false)
  const { state, dispatch } = useContext(AuthContext)

  useEffect(() => {
    if (!state?.token) return

    const [, stockId] = location.pathname.match(matchStockId)

    httpGet(`/details/${stockId}`, state.token)
      .then(({ data }) => {
        setActiveCar(data)
        setCarPhotos(buildPhotoGallery(data))
        updateTimestamp()

        setLoading(false)
      }).catch(error => {
        if (handleUserDeauth(error, dispatch)) return
        logError(error)
        //TODO: Handle non-authorization based exceptions
      })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.token])

  return <div className='details'>
    {loading
      ? <div data-testid='loading-ring-container' className='loading-ring-container'>
        <div className='loading-ring' />
      </div>
      : <Fragment>
        <BarHeader {...activeCar?.description} />
        <div className='details-carousel'>
          <Row>
            <Col lg='8' md='8'>
              {carPhotos && carPhotos.length
                ? <ImageGallery
                  data-testid='image-gallery'
                  items={carPhotos}
                  showPlayButton={false}
                  showBullets={true}
                />
                : <div className='details-no-photo'><img src={noPhoto} alt='no-car-images' /></div>}
            </Col>
            <Col lg='4' md='4' className='details-carousel-info'>
              <div className='details-carousel-info-top'>
                <div>
                  <Link to='/Reservation/' state={{
                    ...activeCar.description,
                    carPhoto: Array.isArray(activeCar.glamourphotos) && activeCar.glamourphotos[0]?.image_url
                  }}>
                    <button>Reserve</button>
                  </Link>
                </div>
                <div className='details-carousel-info-top-price'>
                  {activeCar.description ? `$${numberWithCommas(activeCar.description.price)}` : '0.00'}
                </div>
                <div className='details-carousel-info-top-tiny'>* This price does not include tax, tag, title, inspection, smog and/or registration fees.</div>
                <div className='details-carousel-info-top-mileage'>
                  {activeCar.description ? numberWithCommas(activeCar.description.mileage) : '0'} Miles
                </div>
              </div>
              <div className='details-carousel-info-bottom'>
                <div className='availability-box'>
                  <Row>
                    <div className='availability-box-header'>Estimated Availability</div>
                    <div
                      role='button'
                      tabIndex={0}
                      className='info-icon'
                      onClick={() => setInfoModal(prevModalStatus => !prevModalStatus)}
                      onKeyPress={() => setInfoModal(prevModalStatus => !prevModalStatus)}
                    >i</div>
                    {infoModal &&
                      <div className='info-modal'>
                        The availability of vehicles is determined based on the manufacturers' build schedules for the new cars that have been ordered to
                        replace the vehicles being sold through CarVantedge, and is estimated to the best of our ability based on the schedules provided by
                        the manufacturers.
                        </div>
                    }
                  </Row>
                  <div className='availability-box-text'>{formatWeeksTimeString(activeCar.description?.availability)}</div>
                  <div className='availability-box-message'>{formatAvailabilityNote(activeCar.description?.availability)}</div>
                </div>
                <BasicCarInfo
                  className='text-uppercase'
                  header='LOCATION'
                  info={`${activeCar.location?.city || ''}, ${activeCar.location?.state || ''}`}
                />
                <VinCarInfo info={activeCar.description?.vin} />
                <BasicCarInfo header='EXTERIOR COLOR' info={activeCar.description?.exterior_color || 'Coming Soon'} />
                <BasicCarInfo header='INTERIOR COLOR' info={activeCar.description?.interior_color || 'Coming Soon'} />
                <BasicCarInfo header='BODY' info={activeCar.description?.body || ''} />
                <BasicCarInfo header='SIRIUS XM' info='Yes' />
              </div>
            </Col>
          </Row>
          <div className='details-carousel-info-bottom-mobile'>
            <div className='availability-box'>
              <Row>
                <div className='availability-box-header'>Estimated Availability</div>
                <div
                  role='button'
                  tabIndex={0}
                  className='info-icon'
                  onClick={() => setInfoModal(prevModalStatus => !prevModalStatus)}
                  onKeyPress={() => setInfoModal(prevModalStatus => !prevModalStatus)}
                >i</div>
                {infoModal && <div className='info-modal'>
                  The availability of vehicles is determined based on the manufacturers' build schedules for the new cars that have been ordered to
                  replace the vehicles being sold through CarVantedge, and is estimated to the best of our ability based on the schedules provided by
                  the manufacturers.
                  </div>}
              </Row>
              <div className='availability-box-text'>{formatWeeksTimeString(activeCar.description?.availability)}</div>
              <div className='availability-box-message'>{formatAvailabilityNote(activeCar.description?.availability)}</div>
            </div>
            <BasicCarInfo header='LOCATION' info={`${activeCar.location?.city || ''} ${activeCar.location?.state || ''}`} />
            <VinCarInfo info={activeCar.description?.vin} />
            <BasicCarInfo header='EXTERIOR COLOR' info={activeCar.description?.exterior_color || ''} />
            <BasicCarInfo header='INTERIOR COLOR' info={activeCar.description?.interior_color || ''} />
            <BasicCarInfo header='BODY' info={activeCar.description?.body || ''} />
          </div>
        </div>
        <hr />

        <div className='details-details'>
          <div className='details-section-header'>DETAILS</div>
          <Row className='details-details justify-content-lg-around'>
            <Col className='details-details-section' md={4} sm={6} xs={6}>
              <BasicCarInfo
                header='ENGINE / CYLINDER'
                info={activeCar.specifications?.engine || ''} />
              <BasicCarInfo
                header='TRANS'
                info={activeCar.specifications?.transmission || ''} />
              <BasicCarInfo
                header='SEATING'
                info={activeCar.specifications?.seating || ''} />
              <BasicCarInfo
                header='INTERIOR'
                info={activeCar.specifications
                  ? <div>
                    <div>{activeCar.specifications.interior.type}</div>
                    <div>{activeCar.specifications.interior.features.map(item => item)}</div>
                  </div>
                  : ''}
              />
            </Col>
            <Col className='details-details-section' md={4} sm={6} xs={6}>
              {activeCar.specifications && activeCar.specifications.roof &&
                <BasicCarInfo
                  header='ROOF STYLE'
                  info={activeCar.specifications?.roof || ''}
                />}
              <BasicCarInfo header='POWER' info={activeCar.specifications?.power_equip.map(item => item) || ''} />
            </Col>
            <Col>
              <BasicCarInfo header='A/C' info={activeCar.specifications?.ac || ''} />
              <BasicCarInfo header='RADIO' info={activeCar.specifications?.radio.map(item => item) || ''} />
            </Col>
          </Row>
        </div>
        <hr />
        <div className='details-additional-info'>
          <div className='details-section-header'>ADDITIONAL INFORMATION</div>
          <Row>
            {activeCar.additional_information
              ? activeCar.additional_information.map(damage => <DamagePhoto damage={damage} />)
              : <div className='details-additional-info-null'>
                We currently have no additional information on this vehicle. Please keep in mind that there may be normal wear and tear
                that has not been reported on this vehicle. While these vehicles are being offered AS IS, we stand behind every vehicle we
                sell and will work hard to ensure your vehicle buying experience with us is honorable and well received by you!
              </div>}
          </Row>
        </div>
        <hr />
      </Fragment>
    }
  </div>
}

export default Details

Details.defaultProps = {
  activeCar: {
    glamourphotos: []
  }
}
