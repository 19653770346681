import React from 'react'

const VinCarInfo = ({ info }) =>
    info ? <div className="basic-info">
        <div className="basic-info-header">VIN</div>
        <div className='basic-info-vin'>
            <span>{info.slice(0, info.length - 6)}</span>
            <b>{info.slice(info.length - 6)}</b>
        </div>
    </div>
        : ''

export default VinCarInfo