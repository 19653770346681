import { Col, Row } from 'react-bootstrap'

import ModalImage from 'react-modal-image'
import React from 'react'
import noPhoto from '@Assets/noPhoto.png'

const DamagePhoto = ({ damage }) =>
  <Col className='details-additional-info-field' lg={6} md={6} sm={12}>
    <Row>
      {damage.image_url === 'http://www.webvrsarchivedphotos.com/photos/'
        ? <img className='noPhoto' src={noPhoto} alt='no-car-images' />
        : <ModalImage className='modal-image' large={damage.image_url} smallSrcSet={damage.image_url} />}
      <div className='details-additional-info-field-text'>{damage.description}</div>
    </Row>
  </Col>

export default DamagePhoto
