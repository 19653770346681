import React from 'react'

const BasicCarInfo = ({ header, info, className }) =>
  <div className={`${className} basic-info`}>
    <div className='basic-info-header'>
      {header}
    </div>
    <div className='basic-info-info'>
      {Array.isArray(info)
        ? info.map((item, index) => <div data-testid='car-info' key={index}>{item}</div>)
        : <div data-testid='car-info'>{info}</div>}
    </div>
  </div>

export default BasicCarInfo
